/* eslint-disable indent */
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import jexcel from 'jspreadsheet-ce'
import jsonTable from '@/data/source/local/json/tableproduct.json'
import {
  getCurrentInstance,
  onMounted,
  ref,
  watch,
  toRefs,
  onBeforeUnmount
} from 'vue'
import {
  useRoute
} from 'vue-router'
import {
  courierComissionDetailUseCase,
  jenisKendaraanUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'TableCourierComission',
  props: {
    Id: {
      default: () => null
    },
    Posisition: {
      default: () => null
    },
    DataHd: {
      default: () => null
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const {
      Id,
      Posisition,
      DataHd
    } = toRefs(props)
    const route = useRoute() as any
    const store = useStore()
    const showSubmitExcel = ref(false)
    const isCheck = ref(false)
    const bonus = ref(0)
    const dataExcel = ref([{}])
    const positionTransportationType: any = ref(0)
    const listTransportationType: any = ref([])
    const stringComission = () => (!isCheck.value ? 'Resi' : 'Kilogram')
    /** variable untuk konfigurasi jexcel */
    const jExcelOptions = ref({
      data: dataExcel.value,
      hideIndex: true,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowDeleteRow: route.meta.permission.update,
      allowInsertRow: route.meta.permission.update,
      contextMenu: false,
      editable: route.meta.permission.update,
      columns: [{
        name: 'Id',
        title: 'Id',
        type: 'hidden'
      },
      {
        name: 'Min',
        title: 'Min',
        type: 'number',
        width: '15',
      },
      {
        name: 'Max',
        title: 'Max',
        type: 'number',
        width: '15',
      },
      {
        name: 'Komisi',
        title: ' ',
        type: 'number',
      },
      ],
      nestedHeaders: [
        [{
          title: 'Berat',
          colspan: '2',
        },
        {
          title: 'Komisi',
          colspan: '1',
          // rowspan: '2'
        },
          // {
          //   title: 'Jenis Pengiriman',
          //   colspan: '4',
          // },
          // {
          //   title: 'Dimensi',
          //   colspan: '3',
          // },
        ]
      ] as any,
      columnSorting: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onchange: (instance: any, cell: any, x: any, y: any, value: any) => { },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onselection: (instance: any, cell: any, x: any, y: any, value: any) => { }
    })
    const jExcelObj = ref(null) as any
    const onTableChange = (instance: any, cell: any, x: any, y: any, value: any) => {
      if (!showSubmitExcel.value) {
        showSubmitExcel.value = true
      }
      jExcelObj.value.ignoreEvents = true
      if (!Number.isNaN(Number(value))) {
        jExcelObj.value.setValue(cell, Number(value))
      } else {
        jExcelObj.value.setValue(cell, '')
      }
      jExcelObj.value.ignoreEvents = false
    }

    /** Function untuk inisialisasi jexcel */
    const initExcel = async () => {
      const docs = document.getElementById('spreadsheet')
      jExcelOptions.value.onchange = onTableChange
      jExcelObj.value = jexcel(docs, jExcelOptions.value)
      jExcelObj.value.hideIndex()
      // jExcelObj.value.onchange = onTableChange
    }

    /** Function untuk mengambil data tabel
     * pengaturan besaran komisi dari API
     */
    const getComissionAmount = () => {
      store.dispatch('showLoading')
      courierComissionDetailUseCase.getAllDetailKomisiJenisKendaraan({
        custom: `KomisiKurirHdId eq ${Id.value} AND MsJenisKendaraanId  eq ${positionTransportationType.value}`,
        expand: '&$expand=MsKomisiKurirDetails($select=Id,Komisi,Max,Min;$orderby=Min asc)'
      }).then((response: any) => {
        // console.log('response komisi', response)
        if (!response.error) {
          if (response.result.length > 0) {
            isCheck.value = response.result[0].IsKg
            bonus.value = response.result[0].Bonus
            dataExcel.value = response.result[0].MsKomisiKurirDetails
            jExcelObj.value.setData((dataExcel.value.length > 0) ? dataExcel.value : [{}])
          } else {
            $toast.add({
              severity: 'error',
              detail: 'Gagal mengambil besaran komisi, silahkan coba kembali',
              group: 'bc',
              life: 3000
            })
          }
        } else {
          $toast.add({
            severity: 'error',
            detail: 'Gagal mengambil besaran komisi, silahkan coba kembali',
            group: 'bc',
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    /** Function untuk mengambil data tabel
     * pengaturan besaran prediksi komisi dari API
     */
    const getCourierComissionPrediction = () => {
      store.dispatch('showLoading')
      courierComissionDetailUseCase.getPredictionComissionCourier(Id.value).then((response: any) => {
        if (!response.error) {
          isCheck.value = true
          dataExcel.value = response.result
          jExcelObj.value.setData((dataExcel.value.length > 0) ? dataExcel.value : [{}])
        } else {
          $toast.add({
            severity: 'error',
            detail: 'Gagal mengambil besaran predikdi komisi, silahkan coba kembali',
            group: 'bc',
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    /** Fetch data untuk list jenis
     * kendaraan dri CourierDetail
     */
    // const getTransportationType = () => {
    //   if (Id.value) {
    //     courierComissionDetailUseCase.getKomisiJenisKendaraan(Id.value).then((response: any) => {
    //       if (!response.error) {
    //         listTransportationType.value = response.result
    //         if (listTransportationType.value.length > 0) {
    //           positionTransportationType.value = listTransportationType.value[0].KurirMsJenisKendaraanId
    //           getComissionAmount()
    //         }
    //       }
    //     })
    //   }
    // }

    /** Fetch data untuk list jenis
     * kendaraan dri CourierDetail
     */
    // const getTransportationType = () => {
    //   if (Id.value) {
    //     jenisKendaraanUseCase.getAll({
    //       select: '&$select=Id,Kapasitas,Kode,Nama'
    //     }).then((response: any) => {
    //       if (!response.error) {
    //         listTransportationType.value = response.result.map((x: any) => ({
    //           Id: x.Id,
    //           Kapasitas: x.Kapasitas,
    //           Kode: x.Kode,
    //           Nama: x.Nama
    //         }))
    //         if (listTransportationType.value.length > 0) {
    //           positionTransportationType.value = listTransportationType.value[0].Id
    //           getComissionAmount()
    //         }
    //       }
    //     })
    //   }
    // }

    const getTransportationType = async () => {
      if (DataHd.value) {
        const tempArrTransportationType = await cloneDeep(DataHd.value.KurirMsJenisKendaraans)
        listTransportationType.value = await tempArrTransportationType.map((x: any) => ({
          Id: x.Id,
          Kapasitas: x.Kapasitas,
          Kode: x.Kode,
          Nama: x.Nama
        }))
        if (listTransportationType.value.length > 0) {
          positionTransportationType.value = listTransportationType.value[0].Id
          getComissionAmount()
        }
      }
    }

    /** funtion untuk menentukan css class dari button jenis kendaraan */
    const classItemMenu = (val: any) => {
      if (val.Id === positionTransportationType.value) {
        return 'chip-orange'
      }
      return 'grey-bg-no-border'
    }

    /** function untuk perpindahan posisi jenis kendaraan */
    const changePositionTransType = async (val: any) => {
      positionTransportationType.value = await val.Id
      await jExcelObj.value.destroy()
      await initExcel()
      getComissionAmount()
    }

    const proccessSaveDataExcel = async (dataForm: any) => {
      store.dispatch('showLoading')
      let response: any
      if (Posisition.value === 1) {
        response = await courierComissionDetailUseCase.submitDataExcel(dataForm)
      } else if (Posisition.value === 2) {
        response = await courierComissionDetailUseCase.submitDataPredictionComissionCourier(dataForm)
      }
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    /** mapping model untuk kirim ke API */
    const submitDataExcel = () => {
      const value = jExcelObj.value.getData()
      const dataForm = []
      for (let i = 0; i < value.length; i++) {
        const zeroSizeArr = value[i]

        /** Mapping array untuk replace value '' menjadi null */
        const mapZeroSizeArr: any = zeroSizeArr.map((response: any) => {
          if (response === '') {
            response = null
          }
          return response
        })
        const objArr = {
          // Id: mapZeroSizeArr[0],
          Min: mapZeroSizeArr[1],
          Max: mapZeroSizeArr[2],
          Komisi: mapZeroSizeArr[3],
          // MsKomisiKurirHdId: Id.value,
          // IsPerKg: isCheck.value,
          // KurirMsJenisKendaraanId: positionTransportationType.value,
          // eslint-disable-next-line no-unneeded-ternary
          // IsEdited: mapZeroSizeArr[0] === null ? false : true
        }
        dataForm.push(objArr)
      }
      if (dataForm.length > 0) {
        let tempData
        if (Posisition.value === 1) {
          tempData = {
            KurirMsJenisKendaraanId: positionTransportationType.value,
            MsKomisiKurirHdId: Id.value,
            Bonus: bonus.value,
            IsPerKg: isCheck.value,
            KurirDetails: dataForm
          }
        } else if (Posisition.value === 2) {
          tempData = {
            MsKomisiKurirHdId: Id.value,
            KurirPrediksi: dataForm
          }
        }
        proccessSaveDataExcel(tempData)
      }
    }

    watch(isCheck, async (val) => {
      console.log('val check', val)
      // jExcelObj.value.destroy()
      // initExcel()
      // getComissionAmount()
    })

    const intialData = (isDestroy = false, tabIndex = 1) => {
      // console.log('by indexing', tabIndex)
      if (isDestroy === true) {
        jExcelObj.value.destroy()
      }
      initExcel()
      if (tabIndex === 1) {
        getTransportationType()
      } else if (tabIndex === 2) {
        getCourierComissionPrediction()
      }
    }

    onMounted(() => {
      intialData()
      // console.log('init data', DataHd.value)
    })

    onBeforeUnmount(() => {
      jExcelObj.value.destroy()
    })
    return {
      submitDataExcel,
      isCheck,
      classItemMenu,
      listTransportationType,
      changePositionTransType,
      dataExcel,
      stringComission,
      bonus,
      route,
      intialData
    }
  }
}
